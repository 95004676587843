import * as React from "react"
import { PageProps, graphql } from "gatsby"

import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

type DataProps = {
  site: {
    buildTime: string
  }
}

const HomePage: React.FC<PageProps<DataProps>> = () => (
  <Layout>
    <Seo title="Hirlomat: Design Consultant for Industry, Architecture and Automotive." />
    <div className="container">
      <div className="d-flex align-items-center mt-5">
        <StaticImage
          width={750}
          src="../images/logo.png"
          alt="Hirlomat logo"
          placeholder="blurred"
          className="rounded mx-auto"
        />
      </div>
      <div className="d-flex flex-column align-items-center text-center mt-5">
        <h1 className="fw-bold">DESIGN AND PRODUCTION OF FIBRE COMPOSITES AND METAL CONSTRUCTIONS.<br/>FOR INDUSTRY, ARCHITECTURE, MOTORSPORT, AND DEFENSE.</h1>
        <p>IF YOU NEED A PROTOTYPE OR HIGH-VOLUME PRODUCTION – SEND US A REQUEST!</p>
      </div>
      <div className="d-flex flex-column align-items-center mt-5">
        <p>Contact:</p>
        <p className="m-0 p-0">Stephan Hirl Design Einzelunternehmen</p>
        <p className="m-0 p-0">Mertsee 10</p>
        <p className="m-0 p-0">D-84326 Falkenberg</p>
        <p className="m-0 p-0"><a className="text-gray" href="mailto:info@hirlomat.com">info@hirlomat.com</a></p>
      </div>
    </div>
  </Layout>
)

export default HomePage

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
